import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown, faChevronUp, faCircle, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { ExecutorModalComponent } from '../executor-modal/executor-modal.component';
import { TypeChangeEnum } from '../../core/enums/type-change.enum';
import { DeclarationsService } from '../../core/services/declarations/declarations.service';
import { CommentModalComponent } from '../comment-modal/comment-modal.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';



@Component({
  selector: 'app-declaration-phases',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule, FormsModule, ExecutorModalComponent, CommentModalComponent, CheckboxModule, DropdownModule, InputTextModule, ProgressSpinnerModule],
  templateUrl: './declaration-phases.component.html',
  styleUrl: './declaration-phases.component.scss'
})
export class DeclarationPhasesComponent {
  @Input() phases: any | undefined;
  @Input() isMultiple: number;
  @Input() format: string;
  @Input() isBlocked: boolean;
  @Output() commentEvent = new EventEmitter<any>();
  @Output() changesEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();

  @Output() disableBankAccountAndPresentation = new EventEmitter<any>();

  @ViewChild('modal') modal: ExecutorModalComponent;
  @ViewChild('commentModal') commentModal: CommentModalComponent;

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faUser = faUser;
  faPlay = faPlay;
  faCircle = faCircle;

  isSaving = false;

  changes = [];
  changedTasks = [];
  modalData = [];

  isAccordionOpen = false;
  hoveringExecutor = null;
  hoverTask = null;
  hoverPhase = null;

  selectedTask = null;

  allExpanded = false;

  configExecutorModal: any = undefined;
  showExecutorModal: boolean = false;

  configCommentModal: any = undefined;
  showCommentModal: boolean = false;
  user: any;

  constructor(
    private declarationService: DeclarationsService,

  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')).user;
    this.isPhaseOneFinished();
  }

  phaseState(phase: number) {
    let state = 0;
    let disabled = 0;
    if(this.phases[phase] && this.phases[phase].tasks.length > 0) {

      for (let task of this.phases[phase].tasks) {
        if (task.value && task.isDisabled === false) {
          state = state + 1;
        }
        if(task.isDisabled === true) {
          disabled = disabled + 1;
        }
      }
      if(state === this.phases[phase].tasks.length - disabled && state > 0) {
        if(this.phases[phase].tasks.includes(task => task.name === "Cuenta bancaria")){
          this.disableBankAccountAndPresentation.emit(true);
        }
        return ["Finalizada","#5cbc9c"];
      } else if (state < this.phases[phase].tasks.length - disabled && state > 0) {
        return ["En curso","#ff9100"];
      } else if(state === this.phases[phase].tasks.length - disabled && state === 0) {
        return ["Bloqueada","#5cbc9c"];
      } else {
        return ["Pendiente","#cecece"];
      }
    } else {
      return "#cecece";
    }
  }


  showFase(index) {
    this.phases[index].show = !this.phases[index].show;
  }

  isString(value) {
    return typeof value === 'string';
  }


  async selectDeselectPhases(task, phase) {
    this.isSaving = true;
    let startDeselecting = false;
    let startSelecting = true;
    if (!task.value) {
      for (let p of this.phases) {
        for (let t of p.tasks) {
          if(t.value) {
            if (startDeselecting) {
              if(t.value) {
                if (t.ids) {
                  t.ids.forEach(id => {
                    this.changedTasks.push( {id: id, type: TypeChangeEnum.StatusTask, change: null});
                  });
                } else {
                  this.changedTasks.push( {id: t.id, type: TypeChangeEnum.StatusTask,change: null});
                }
                this.modalData.push({task: t.name, phase: p.phase});
              }
            }
          }
          if (p === phase && t === task) {
            if (t.ids) {
              t.ids.forEach(id => {
                this.changedTasks.push( {id: id, type: TypeChangeEnum.StatusTask, change: null});
              });
            } else {
              this.changedTasks.push( {id: t.id, type: TypeChangeEnum.StatusTask,change: null});
            }
            this.modalData.push({task: t.name, phase: p.phase});          
            startDeselecting = true;
          }
        }
      }
    }
    else {
      for (let p of this.phases) {
        
        for (let t of p.tasks) {
          if(t.value === false) {
            if (startSelecting) {
              t.value = true;
              if (t.ids) {
                t.ids.forEach(id => {
                  this.changedTasks.push( {id: id, type: TypeChangeEnum.StatusTask, change: true});
                  t.executor = this.user.name;
                  this.changedTasks.push( {id: id, type: TypeChangeEnum.Executor, change: this.user.id});

                });
              } else {
                this.changedTasks.push( {id: t.id, type: TypeChangeEnum.StatusTask,change: true});
                t.executor = this.user.name;
                this.changedTasks.push( {id: t.id, type: TypeChangeEnum.Executor, change: this.user.id});
              }            
            }
          }
          if (p === phase && t === task) {
            if (t.ids) {
              t.ids.forEach(id => {
                this.changedTasks.push( {id: id, type: TypeChangeEnum.StatusTask, change: t.value});
                t.executor = this.user.name;
                this.changedTasks.push( {id: id, type: TypeChangeEnum.Executor, change: this.user.id});
              });
            } else {
              this.changedTasks.push( {id: t.id, type: TypeChangeEnum.StatusTask, change: t.value});
              t.executor = this.user.name;
              this.changedTasks.push( {id: t.id, type: TypeChangeEnum.Executor, change: this.user.id});
            }
            startSelecting = false;

          }

        }
      }
    }
    if (this.isMultiple > 1) {
      if (this.changedTasks.length > this.isMultiple) {
        if (this.changedTasks[0].change === null) {
          this.openCommentModal();
        } else {
          await this.declarationService.massTaskStatusUpdate(this.changedTasks);
          this.changedTasks.forEach(task => {
            this.changes.push(task);
            this.sendChanges();
          });
          this.changedTasks = [];
        }
      } else {
        this.changedTasks.forEach(task => {
          this.changes.push(task);
          this.sendChanges();
        });
        this.changedTasks = [];
      }
    } else {
      if (this.changedTasks.length > 1) {
        if (this.changedTasks[0].change === null) {
          this.openCommentModal();
        } else {
          await this.declarationService.massTaskStatusUpdate(this.changedTasks);
          this.changedTasks = [];
        }
      } else if (this.changedTasks.length === 1) {
        await this.declarationService.taskStatusUpdate(this.changedTasks[0]);
        this.changedTasks = [];
      }
      this.isPhaseOneFinished()


    }
    if (this.modalData.length <= 1) {
      this.modalData = [];  
    }
    this.isSaving = false;
  }

  getActiveTasks(tasks) {
    return tasks.filter(task => !task.isDisabled).length;
  }

  isPhaseOneFinished() {
    let state = 0;
    let disabled = 0;
    if(this.phases[0] && this.phases[0].tasks.length > 0) {

      for (let task of this.phases[0].tasks) {
        if (task.value && task.isDisabled === false) {
          state = state + 1;
        }
        if(task.isDisabled === true) {
          disabled = disabled + 1;
        }
      }
      if(state === this.phases[0].tasks.length - disabled && state > 0) {
          if(this.phases[0].tasks.some(task => task.name ==="Cuenta bancaria" || task.name === "Tipo presentación - fichero")){
            this.disableBankAccountAndPresentation.emit(true);
          } else {
            this.disableBankAccountAndPresentation.emit(false);
          }
      } else {
        this.disableBankAccountAndPresentation.emit(false);
      } 
    }
  }


  openExecutorModal(task): void {
    this.selectedTask = task;
    
    this.showExecutorModal = true;
  }

  closeExecutorModal() {
    this.showExecutorModal = false;
  }

  onExecutorSelect(executor): void {
    this.selectedTask.executor = executor.name;
    if (this.isMultiple > 1) {
      for (let id of this.selectedTask.ids) {
        this.changes.push({id: id, type: TypeChangeEnum.Executor, change: executor.id});
      }
      this.sendChanges();
    } else {
      this.declarationService.taskExecutorUpdate(this.selectedTask.id, executor.id);
    }
  }

  openCommentModal(): void {
    this.showCommentModal = true;
  }

  closeCommentModal() {
    this.phases.forEach(phase => {
      phase.tasks.forEach(task => {
        if (this.isMultiple > 1) {
          if (this.changedTasks[0].id === task.ids[0]) {
            task.value = true;
          }
        } else {
          if (this.changedTasks[0].id === task.id) {
            task.value = true;
          }
        }
      });
    });
    this.closeEvent.emit();
    this.showCommentModal = false;
    this.changedTasks = [];
    this.modalData = [];
  }

  comment(comment) {
    if (this.isMultiple > 1) {
      this.phases.forEach(phase => {
        phase.tasks.forEach(task => {
          if (this.changedTasks.some(changedTask => changedTask.id === task.ids[0])) {

            task.value = false;
          }
        });
      });
      this.changedTasks.forEach(task => {
        this.changes.push(task);
        this.sendChanges();
      });
    } else {
      this.phases.forEach(phase => {
        phase.tasks.forEach(task => {
          if (this.changedTasks.some(changedTask => changedTask.id === task.id)) {
            task.value = false;
          }
        });
      });
      this.declarationService.massTaskStatusUpdate(this.changedTasks);
    }
    this.commentEvent.emit(comment);
    this.showCommentModal = false;
    this.changedTasks = [];
    this.modalData = [];
  }

  getInitials(name: string) {
    if(name){
      return name.split(' ').map(word => word[0].toUpperCase()).join('');
    }
    else{
      return 'N/A';
    }
  }

  sendChanges() {
    this.changesEvent.emit(this.changes);
  }

  isPointerDisabled(index: number): boolean {
    if (index === 0) {
      return false;
    }
  
    const previousPhaseState = this.phaseState(index - 1)[0];
    const currentPhaseState = this.phaseState(index)[0];
  
    if (previousPhaseState !== 'Finalizada') {
      return true;
    }
  
    if (currentPhaseState === 'Bloqueada') {
      return true;
    }
  
    return false;
  }
  
  
  
  

  getNgStylePointer(index: number): { [key: string]: string } {
    return this.isPointerDisabled(index) ? { 'pointer-events': 'none' } : {};
  }

  isAllPhaseDisabled(index) {
    let disabled = 0;
    for (let task of this.phases[index].tasks) {
      if(task.isDisabled === true) {
        disabled = disabled + 1;
      }
    }
    return disabled === this.phases[index].tasks.length;
  }

  isTaskBlocked(phase, task) {
    let phaseHasInputs = false;
    let index = 1
    this.phases[phase].tasks.forEach(t => {
      if (t.type !== 'CHECK'){
        phaseHasInputs = true;
      }
    });
    if (phaseHasInputs){
      while(task > 0 && this.phases[phase].tasks[task - index].isDisabled){
        index = index + 1;
      }
      if(task > 0 && !this.phases[phase].tasks[task - index].value){
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
}
