import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { RelatedDeclarationModalComponent } from '../related-declaration-modal/related-declaration-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faCircle, faCircleCheck, faClockRotateLeft, faFilePlus } from '@fortawesome/pro-solid-svg-icons';
import { PresentationTypeEnum } from '../../core/enums/presentation-type.enum';
import { Router } from '@angular/router';
import { DeclarationsService } from '../../core/services/declarations/declarations.service';
import { SortEvent } from 'primeng/api';
import { DEPARTMENTS_TYPES } from '../../core/constants/client.constants';
import _ from 'lodash';


@Component({
  selector: 'app-prime-table',
  standalone: true,
  imports: [TableModule, CommonModule, MultiSelectModule, FormsModule, DropdownModule, CheckboxModule, FontAwesomeModule],
  templateUrl: './prime-table.component.html',
  styleUrl: './prime-table.component.scss'
})
export class PrimeTableComponent {
  @Input() configTable: any;
  @Input() filtersData: any;
  @Output() declarationChange: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('newDecModal') newDecModal: RelatedDeclarationModalComponent;

  declarations: any;
  filteredDeclarations: any;
  selectedModels: any = [];
  selectedClients: any = [];
  selectedFase: any;
  selectedPresentationTypes: any;
  selectedResponsible: any;
  selectedExecutor: any;
  selectedClientCodes: any = [];

  originalModels: any = [];

  selectedHash: any = undefined;
  showRelatedDecModal: boolean = false;
  order = -1;
  faCircleCheck = faCircleCheck;
  faCircle = faCircle;
  faChevronDown = faChevronDown;
  faFilePlus = faFilePlus;
  faClockRotateLeft = faClockRotateLeft;

  PresentationTypeEnum = PresentationTypeEnum;
  departmentsTypes = DEPARTMENTS_TYPES;
  clientCodes: any = [];

  filters: any = {
    model: {
      value: this.selectedModels,
      filter: (obj: any): boolean => {
        return this.selectedModels.contains(obj.model.code);
      }
    },
  };



  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    const savedFilters = localStorage.getItem('primeTableFilters');
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      this.selectedModels = filters.selectedModels || undefined;
      this.selectedClients = filters.selectedClients || undefined;
      this.selectedFase = filters.selectedFase || undefined;
      this.selectedPresentationTypes = filters.selectedPresentationTypes || undefined;
      this.selectedResponsible = filters.selectedResponsible || undefined;
      this.selectedExecutor = filters.selectedExecutor || undefined;
      this.selectedClientCodes = filters.selectedClientCodes || undefined;
    }
   
    if (this.filtersData) {
      this.filtersData.clients = this.filtersData.clients.sort((a, b) => a.name.localeCompare(b.name));
      this.clientCodes = this.filtersData.clients.filter((client) => client.code).sort((a, b) => a.code - b.code);
      this.filtersData.users = this.filtersData.users.sort((a, b) => a.name.localeCompare(b.name));
      const maxPhases = _.maxBy(this.declarations, item => item.tasks.length)?.tasks.length;
      for (let i = 0; i < maxPhases; i++) {
        let phase = {name: 'Fase ' + (i+1), id:(i+1), selected: false};
        this.filtersData.fases.push(phase);
      }
    }
    if(this.filtersData.models) {
      this.filtersData.models.forEach(model => {
        model.department = this.getDepartmentLabel(model.department);
        model.models = model.models.sort((a, b) => a.name.localeCompare(b.name)); 
      });
      this.originalModels = this.filtersData.models;

    }
    if (this.configTable && this.configTable.data) {
      this.filteredDeclarations = this.configTable.data.declarations;
      this.declarations = [...this.configTable.data.declarations];
      this.applyCustomFilter();
      this.modifyFiltersContent('');

    }

  }

  onClientSelected(event) {
    if(!event) {
      this.selectedClients = [];
    } else {
      this.selectedClients = event.value;
    }
    this.applyCustomFilter()
    this.modifyFiltersContent('client');
  }

  onClientCodeSelected(event) {
    if(!event) {
      this.selectedClientCodes = [];
    } else {
      this.selectedClientCodes = event.value;
    }
    this.applyCustomFilter()
    this.modifyFiltersContent('clientCode');
  }

  onPhaseChange(event) {
    this.selectedFase = event.value;
    this.applyCustomFilter()
  }

  onModelChange(event) {
    if(!event) {
      this.selectedModels = [];
    } else {
      this.selectedModels = event.value;
    }
    this.applyCustomFilter()
    this.modifyFiltersContent('model');

  }

  onPresentationTypesSelected(event) {
    this.selectedPresentationTypes = event.value;
    this.applyCustomFilter()
    this.modifyFiltersContent('presentationType');
  }

  onResponsibleSelected(event) {
    this.selectedResponsible = event.value;
    this.applyCustomFilter()
    this.modifyFiltersContent('responsible');
  }

  onExecutorSelected(event) {
    this.selectedExecutor = event.value;
    this.applyCustomFilter()
    this.modifyFiltersContent('executor');
  }

  modifyFiltersContent(filter) {
    const visibleData = {
      models: _.map(this.filteredDeclarations, 'model.name'),
      clients: _.map(this.filteredDeclarations, 'client'),
      presentationTypes: _.map(this.filteredDeclarations, 'presentationType'),
      responsibles: _.map(this.filteredDeclarations, 'responsible').filter(Boolean),
      executors: _.map(this.filteredDeclarations, 'executor').filter(Boolean),
      maxPhases: _.maxBy(this.filteredDeclarations, item => item.tasks.length)?.tasks.length || 0,
    };
  
    if (filter !== 'model' || this.selectedModels.length === 0) {
      this.filtersData.models = _.map(this.originalModels, department => ({
        ...department,
        models: _.filter(department.models, model => visibleData.models.includes(model.name)),
      }));
    }
  
    if (filter !== 'client' || this.selectedClients.length === 0) {
      this.filtersData.clients = _.chain(visibleData.clients)
        .uniqBy('code')
        .sortBy('name')
        .value();

    }
  
    if (filter !== 'presentationType' || this.selectedPresentationTypes == null) {
      this.filtersData.presentationTypes = _.uniq(visibleData.presentationTypes);
    }
  
    if (filter !== 'responsible' || this.selectedResponsible == null) {
      this.filtersData.users = _.uniqBy(visibleData.responsibles, 'name');
    }
  
    if (filter !== 'executor' || this.selectedExecutor == null) {
      this.filtersData.executors = _.uniq(visibleData.executors);
    }
  
    if (filter !== 'phases' || this.selectedFase == null) {
      this.filtersData.fases = _.times(visibleData.maxPhases, i => ({
        name: `Fase ${i + 1}`,
        id: i + 1,
        selected: false,
      }));
    }
  }
  
  

  applyCustomFilter() {
    this.filteredDeclarations = this.declarations.filter(declaration => {
      const modelMatch = this.selectedModels.length > 0
        ? this.selectedModels.includes(declaration.model.name)
        : true;
  
      const clientMatch = this.selectedClients.length > 0
        ? this.selectedClients.includes(declaration.client.name)
        : true;
  
      const clientCodeMatch =this.selectedClientCodes.length > 0
      ? this.selectedClientCodes.includes(declaration.client.code)
      : true;
  
      const presentationMatch = this.selectedPresentationTypes
        ? this.selectedPresentationTypes.includes(declaration.presentationType)
        : true;
  
      const responsibleMatch = this.selectedResponsible
        ? this.selectedResponsible.name.includes(declaration.responsible?.name)
        : true;
  
      const executorMatch = this.selectedExecutor
        ? this.selectedExecutor.name.includes(declaration.executor)
        : true;
  
      const phaseMatch = this.selectedFase !== undefined
        ? this.checkPhaseConditions(declaration.tasks, this.selectedFase)
        : true;
      
      this.saveFilters();
      return modelMatch && clientMatch && clientCodeMatch && presentationMatch && responsibleMatch && executorMatch && phaseMatch && clientCodeMatch;
    });
    
  }
  
  checkPhaseConditions(tasks: string[], phaseIndex: number): boolean {
    if (phaseIndex === 1) {
      const currentPhase = tasks[phaseIndex - 1];
      console.log(currentPhase)
      return currentPhase === 'PENDING' || currentPhase === undefined || currentPhase === null || currentPhase === 'STARTED';
    } else {
      const currentPhase = tasks[phaseIndex - 1];
      const previousPhase = tasks[phaseIndex - 2];
      if (currentPhase === 'STARTED') {
        return true; 
      }
      else if (currentPhase === 'PENDING' && previousPhase === 'FINISHED') {
        return true; 
      } else {
        return false; 
      }

    }
  
  
  }
  

  saveFilters() {
    const filters = {
      selectedModels: this.selectedModels,
      selectedClients: this.selectedClients,
      selectedFase: this.selectedFase,
      selectedPresentationTypes: this.selectedPresentationTypes,
      selectedResponsible: this.selectedResponsible,
      selectedExecutor: this.selectedExecutor,
      selectedClientCodes: this.selectedClientCodes
    };
    localStorage.setItem('primeTableFilters', JSON.stringify(filters));
  }
  

  goToDetail(id: string, block?: boolean) {
    this.router.navigate(['/declarations/detail', id],  { state: { block: block } });
  }

  goToLog(id: string) {
    this.router.navigate(['/declarations/log', id]);
  }

  onCheckboxChange(declaration: any) {
    if (declaration.isChecked) {
      this.selectedHash = declaration.hash;
    } else {
      this.selectedHash = undefined;
    }
    this.declarationChange.emit(declaration);
  }

  shouldDisableCheckbox(item: any) {
    if (!this.selectedHash) {
      return false;
    } else {
      return item.hash !== this.selectedHash;
    }
  }
  onSort(event: SortEvent) {
    this.filteredDeclarations.forEach((declaration, index) => {
      if(declaration.type === 'model-name') {
        this.filteredDeclarations.splice(index, 1);
      }
    });


    const { field, order } = event;
    if(field === 'model') {
      this.filteredDeclarations.sort((a, b) => {
          const valueA = a[field].name;
          const valueB = b[field].name;
          const result = valueA.localeCompare(valueB);
          return order * result;
      });

      let modelName = '';
      const updatedDeclarations = [];

      this.filteredDeclarations.forEach(declaration => {
        if (declaration.model.name !== modelName) {
          modelName = declaration.model.name;
          updatedDeclarations.push({ type: 'model-name', name: modelName });
        }
        updatedDeclarations.push(declaration);
      });

      this.filteredDeclarations.splice(0, this.filteredDeclarations.length, ...updatedDeclarations);

    } else if(field === 'clientName') {
      this.filteredDeclarations.sort((a, b) => {
          const valueA = a['client'].name;
          const valueB = b['client'].name;
          const result = valueA.localeCompare(valueB);
          return order * result;
      });
    } else {
      this.filteredDeclarations.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];
        return valueA > valueB ? 1 : -1;
      });
    }
  }

  getDepartmentLabel(departmentValue: string): string {
    const department = this.departmentsTypes.find(dep => dep.value === departmentValue);
    return department ? department.label : '';
  }


}
