<p-table #dt 
  [value]="filteredDeclarations" 
  styleClass="p-datatable-striped p-datatable-sm"
  [globalFilterFields]="['model', 'clientName', 'tasks', 'responsible', 'executor', 'presentationType', 'observations']"
  sortField="model" [sortOrder]="1" 
  [customSort]="true"
  (sortFunction)="onSort($event)"
  [scrollable]="true"
  [virtualScroll]="true"
  [virtualScrollItemSize]="50"
  class="table is-stripped"
>
    <ng-template pTemplate="header">  
      
        <tr>
            @for (header of configTable.headers; track header) {

                @if(header.type === 'text' && header.key !== 'bankAccount' && header.key !== 'client') {
                  <th>
                    <div [ngStyle]="{'display':'flex'}">
                        {{header.name}}
                        <p-sortIcon [pSortableColumn]="header.key" [field]="header.key" />
                    </div>
                </th>
                }
                @if(header.key === 'bankAccount') {
                  <th>
                    <div [ngStyle]="{'display':'flex','font-size':'13px'}">
                        {{header.name}}
                        <!-- <p-sortIcon [pSortableColumn]="header.key" [field]="header.key" /> -->
                    </div>
                </th>
                }
                @if(header.key === 'client') {
                  <th>
                    <div [ngStyle]="{'display':'flex'}">
                      <p-columnFilter field="clientName" matchMode="in" [showMenu]="false">
                        <ng-template pTemplate="filter">
                          <p-multiSelect
                            [options]="filtersData.clients"
                            [(ngModel)]="selectedClientCodes"
                            optionLabel="code"
                            optionValue="code"
                            [filter]="true"
                            filterBy="code"
                            showClear="true"
                            [placeholder]="header.name"
                            (onClear)="onClientCodeSelected($event)"
                            (onChange)="onClientCodeSelected($event)" 
                            [variant]="selectedClientCodes.length > 0 ? 'filled' : 'outlined'"
                            >
                          </p-multiSelect>
                        </ng-template>
                      </p-columnFilter>
                      <p-sortIcon [pSortableColumn]="header.key" [field]="header.key"></p-sortIcon>
                    </div>
                  </th>
                }
                @if(header.type === 'checkbox') {
                    <th></th>
                }
                @if(header.key === 'observations'){
                    <th>
                        <div [ngStyle]="{'display':'flex','font-size':'13px'}">
                            {{header.name}}
                            <!-- <p-sortIcon [pSortableColumn]="header.key" [field]="header.key" /> -->
                        </div>
                    </th>
                }
                @if(header.key === 'model'){
                    <th>
                        <div [ngStyle]="{'display':'flex'}">
                        <p-columnFilter field="model" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect 
                                [options]="filtersData.models"
                                [group]="true"
                                optionLabel="name"
                                optionValue="name"
                                optionGroupLabel="department"
                                optionGroupChildren="models"
                                [(ngModel)]="selectedModels"
                                [placeholder]="header.name" 
                                showClear="true"
                                (onClear)="onModelChange($event)"
                                (onChange)="onModelChange($event)"
                                [variant]="selectedModels.length > 0 ? 'filled' : 'outlined'"
                                />
                            </ng-template>
                        </p-columnFilter>
                        <p-sortIcon pSortableColumn="model" field="model" />
                        </div>
                    </th>
                }
                @if(header.key === 'clientName'){
                    <th>
                      <div [ngStyle]="{'display':'flex'}">
                        <p-columnFilter style="width: 290px" field="clientName" matchMode="in" [showMenu]="false">
                          <ng-template pTemplate="filter">
                            <p-multiSelect
                              [options]="filtersData.clients"
                              [(ngModel)]="selectedClients"
                              optionLabel="name"
                              optionValue="name"
                              [filter]="true"
                              filterBy="name"
                              showClear="true"
                              [placeholder]="header.name"
                              (onClear)="onClientSelected($event)"
                              (onChange)="onClientSelected($event)" 
                                [variant]="selectedClients.length > 0 ? 'filled' : 'outlined'"
                              >
                            </p-multiSelect>
                          </ng-template>
                        </p-columnFilter>
                        <p-sortIcon [pSortableColumn]="header.key" [field]="header.key"></p-sortIcon>
                      </div>
                    </th>
                }
                
                @if(header.key === 'tasks') {
                    <th>
                        <div [ngStyle]="{'display':'flex'}">
                        <p-columnFilter field="tasks" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown 
                                [options]="filtersData.fases"
                                [filter]="false"
                                optionLabel="name"
                                optionValue="id"
                                showClear="true"  
                                [(ngModel)]="selectedFase"
                                [placeholder]="header.name" 
                                (onClear)="onPhaseChange($event)"
                                (onChange)="onPhaseChange($event)"
                                [variant]="selectedFase ? 'filled' : 'outlined'"
                                />
                            </ng-template>
                        </p-columnFilter>
                        <!-- <p-sortIcon [pSortableColumn]="header.key" [field]="header.key" /> -->
                        </div>
                    </th>
                }
                @if(header.key === 'presentationType') {
                    <th>
                        <div [ngStyle]="{'display':'flex'}">
                        <p-columnFilter field="presentationType" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown 
                                [options]="filtersData.presentationTypes"

                                [(ngModel)]="selectedPresentationTypes"
                                [placeholder]="header.name" 
                                showClear="true"
                                (onClear)="onPresentationTypesSelected($event)"
                                (onChange)="onPresentationTypesSelected($event)"
                                [variant]="selectedPresentationTypes ? 'filled' : 'outlined'"
                                />
                            </ng-template>
                        </p-columnFilter>
                        <p-sortIcon [pSortableColumn]="header.key" [field]="header.key" />
                        </div>
                    </th>
                }
                @if(header.key === 'responsible') {
                  <th>
                    <div [ngStyle]="{'display':'flex'}">
                      <p-columnFilter field="responsible" matchMode="equals" [showMenu]="false">
                        <ng-template pTemplate="filter">
                          <p-dropdown 
                            [options]="filtersData.users" 
                            optionLabel="name" 
                            [(ngModel)]="selectedResponsible" 
                            [placeholder]="header.name" 
                            showClear="true"
                            (onClear)="selectedResponsible = null"
                            (onChange)="onResponsibleSelected($event)"
                            [variant]="selectedResponsible ? 'filled' : 'outlined'"
                            >
                          </p-dropdown>
                        </ng-template>
                      </p-columnFilter>
                      <p-sortIcon [pSortableColumn]="header.key" [field]="header.key"></p-sortIcon>
                    </div>
                  </th>
                    
                }
                @if(header.key === 'executor') {
                    <th>
                        <div [ngStyle]="{'display':'flex'}">
                        <p-columnFilter field="executor" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown 
                                [options]="filtersData.users"
                                [filter]="false"
                                optionLabel="name"
                                [(ngModel)]="selectedExecutor"
                                [placeholder]="header.name" 
                                showClear="true"
                                (onClear)="selectedExecutor = null"
                                (onChange)="onExecutorSelected($event)"
                                [variant]="selectedExecutor ? 'filled' : 'outlined'"
                                />
                            </ng-template>
                        </p-columnFilter>
                        <p-sortIcon [pSortableColumn]="header.key" [field]="header.key" />
                        </div>
                    </th>
                }
                
            }
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
          @if(item.type === 'model-name') {
            <td colspan="10" [ngStyle]="{'text-align':'left'}">{{ item.name }}</td>
          }@else {
            @for (header of configTable.headers; track header.key) {
                @if (header.key === 'checkbox'){
                  <td>
                    <p-checkbox 
                    [(ngModel)]="item.isChecked" 
                    [binary]="true" 
                    inputId="binary" 
                    (ngModelChange)="onCheckboxChange(item)"
                    [disabled]="shouldDisableCheckbox(item)"/>
                    
                  </td>
                }@else if (header.key === 'log') {
                  <td><button (click)="goToLog(item['id'])"><fa-icon class="log-icon" [icon]="faClockRotateLeft"></fa-icon></button></td>                        
                } @else{
                    @if (header.key === 'tasks') {
                      <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer', 'width':'150px'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                      @for (task of item.tasks; track task) {
                        @if(task === "FINISHED") {
                          <fa-icon class="check-icon" [icon]="faCircleCheck"></fa-icon>

                        } @else if (task === "STARTED"){
                          <fa-icon class="progress-circle-icon" [icon]="faCircle"></fa-icon>

                        } @else if (task === "PENDING"){
                          <fa-icon class="circle-icon" [icon]="faCircle"></fa-icon>
                        }
                      }
                      </td>
                    } @else if(header.key === 'observations') {
                      <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer', 'text-align':'center'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                        <span>
                          @if(item[header.key] === true) {
                            Sí
                          } @else {
                            No
                          }
                        </span>
                      </td>
                    }
                    @else if(header.key === 'state') {
                      <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                        @if(item['status'] === "FINISHED") {
                          <span class="tag finalized"><fa-icon [icon]="faCircle"></fa-icon>Realizada</span>
                        } @else if(item['status'] === "PROGRESS") {
                          <span class="tag "><fa-icon [icon]="faCircle"></fa-icon>En progreso</span>
                        }
                        @else {
                          <span class="tag not-finalized"><fa-icon [icon]="faCircle"></fa-icon>No procede</span>
                        }
                      </td>

                    } @else if (header.key === 'client') {
                      <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer', 'text-align':'center'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                        {{ item[header.key].code }}
                      </td>
                    } @else if (header.key === 'presentationType') {
                      <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer', 'text-align':'center'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                        {{ item[header.key]  }}
                      </td>
                    } @else if (header.key === 'clientName') {
                      <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                        {{ item.client.name }}
                      </td>
                    } @else if (header.key === 'model') {
                      <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                        {{ item[header.key].name }} {{ (item.type === 'NORMAL' ? '' : item.type[0])}} {{ item.createdAt | date: 'MM'}}{{item[header.key].periodicity[0]}} {{ item.createdAt | date: 'yyyy '}}
                      </td>
                    } @else if (header.key === 'responsible') {
                      <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                      @if (item[header.key] !== null) {
                        {{ item[header.key].name }}
                      } @else {
                       
                      }
                      </td>
                    } 
                    @else if (header.key === 'bankAccount') {
                      <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                        {{ item[header.key] }}
                      </td>
                    }
                    @else if (item[header.key] !== null) {
                      <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                        {{ item[header.key] }}
                      </td>
                    }@else {

                    }
                }
              }
          }
        </tr>
    </ng-template>
</p-table>