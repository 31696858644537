import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import * as ClientConstants from '../../../core/constants/client.constants';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { FiltersService } from '../../../core/services/declarations/filters.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { ClientsService } from '../../../core/services/clients/clients.service';

@Component({
  selector: 'app-identification-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, InputNumberModule],
  templateUrl: './identification-form.component.html',
  styleUrl: './identification-form.component.scss'
})
export class IdentificationFormComponent {
  @Input() clientId: any;
  @Output() createEvent = new EventEmitter<any>();
  @Output() formIsDirty = new EventEmitter<boolean>();

  public clientConstants = ClientConstants;

  responsibleUsers;

  identificationForm = this.fb.group({
    id: [],
    code: ['', Validators.required],
    name: ['', Validators.required],
    codeApplication: [''],
    personType: [''],
    documentType: [''],
    documentNumber: [''],
    constitutionDate: [],
    responsible: [],
    startDate: [],
    endDate: [],
  });

  client: any;
  constructor(
    private fb: FormBuilder,
    private filtersService: FiltersService,
    private clientsService: ClientsService
    ) {
      this.identificationForm.valueChanges.subscribe(() => {
        if (this.identificationForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
     }

  async ngOnInit() {
    this.responsibleUsers = await this.filtersService.getUsers();
    await this.getData();
  }

  async getData() {
    console.log(this.clientId);
    if (this.clientId) {
      this.client = await this.clientsService.getClientById(this.clientId);
      if (this.client) {
        this.client.startDate = this.client.startDate ? new Date(this.client.startDate) : null;
        this.client.endDate = this.client.endDate ? new Date(this.client.endDate) : null;
        this.client.constitutionDate = this.client.constitutionDate ? new Date(this.client.constitutionDate) : null;
        this.client.responsible = this.client.responsible ? this.client.responsible.id : null;
        this.identificationForm.patchValue(this.client);
      }
    }
  }

  async save() {
    
    const response = await this.clientsService.saveClientIdentification(this.identificationForm.value);
    if (!this.clientId){
      this.createEvent.emit(response.id);
    }
  }
}
