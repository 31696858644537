import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBan, faChevronDown, faChevronLeft, faChevronUp, faCircle, faPlay, faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DeclarationsService } from '../../../core/services/declarations/declarations.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ExecutorModalComponent } from '../../../components/executor-modal/executor-modal.component';
import { faPenLine, faUser } from '@fortawesome/pro-light-svg-icons';
import { FiltersService } from '../../../core/services/declarations/filters.service';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { CommentModalComponent } from '../../../components/comment-modal/comment-modal.component';
import { StatusDeclarationEnum } from '../../../core/enums/status-declaration.enum';
import { TypeChangeEnum } from '../../../core/enums/type-change.enum';
import { PresentationTypeEnum } from '../../../core/enums/presentation-type.enum';
import { FormatEnum } from '../../../core/enums/format.enum';
import { DeclarationPhasesComponent } from '../../../components/declaration-phases/declaration-phases.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { InputGroupModule } from 'primeng/inputgroup';
import { ProgressSpinnerModule } from 'primeng/progressspinner';


@Component({
  selector: 'app-declaration-detail',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule, ReactiveFormsModule, RouterModule, FormsModule, PaginationComponent, DeclarationPhasesComponent, CommentModalComponent, DropdownModule, InputTextModule, InfiniteScrollModule, InputGroupModule, ProgressSpinnerModule],
  templateUrl: './declaration-detail.component.html',
  styleUrl: './declaration-detail.component.scss'
})
export class DeclarationDetailComponent {

  @ViewChildren('panel') panels!: QueryList<ElementRef>;
  @ViewChild('modal') modal: ExecutorModalComponent;
  @ViewChild('commentModal') commentModal: CommentModalComponent;

  faBan = faBan;
  faPenLine = faPenLine;
  faPlay = faPlay;
  faCircle = faCircle;
  faChevronLeft = faChevronLeft;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faUser = faUser;
  faRotateLeft = faRotateLeft;

  isAccordionOpen = false;
  hoveringExecutor = null;
  hoverTask = null;
  hoverPhase = null;

  formats = Object.values(FormatEnum)
  presentations = PresentationTypeEnum;

  responsibles = [];
  observations = [];

  declaration: any;

  configExecutorModal: any = undefined;
  showExecutorModal: boolean = false;

  configCommentModal: any = undefined;
  showCommentModal: boolean = false;

  configReactivateModal: any = undefined;
  showReactivateModal: boolean = false;

  selectedTask = null;

  allExpanded = true;

  currentPage = 0;
  itemsForPage = 5;
  maxPages = undefined;

  user = undefined;

  decFinished = false;

  isFormatChanged = false;
  isPresentationChanged = false;
  isAccountChanged = false;
  isAccountDisabled = false;
  isAccountHidden = false;
  isPresentationDisabled = false;

  isBlocked = false;
  today = new Date();

  commentForm = new FormGroup({
    comment: new FormControl('', Validators.required),
  });

  disableAccountPresentations = [
    "Informativa - PDF",
    "Sacich NRC - PDF",
    "Presentación cliente - PDF",
    "Presentación cliente - DAT",
    "A compensar - PDF"
]

  isLoading: boolean;
  constructor(
    private route: ActivatedRoute,
    private declarationService: DeclarationsService,
    private filtersService: FiltersService,
    private router: Router
    ) { 

  }
  async ngOnInit() {
    this.isLoading = true;
    this.user = JSON.parse(localStorage.getItem('user')).user;
    const id = Number(this.route.snapshot.paramMap.get('id'));
    await this.getData(id);
    
    this.isLoading = false;
    this.isBlocked  = history.state.block;
  }

  async getData(id) {
    this.declaration = await this.declarationService.getDeclarationsById(id);
    if (this.declaration?.contract?.bankAccount || this.declaration?.declaration?.bankAccount) {
      this.isAccountChanged =  this.declaration.bankAccount !== (this.declaration?.contract?.bankAccount?.iban || this.declaration?.declaration?.bankAccount);
    }

    if (this.declaration?.contract?.presentationType || this.declaration?.declaration?.presentationType) {
      this.isPresentationChanged = this.declaration.presentationType !== (this.declaration?.contract?.presentationType || this.declaration?.declaration?.presentationType);
    }
    if(this.disableAccountPresentations.includes(this.declaration?.presentationType)) {
      this.isAccountHidden = true;
    } else {
      this.isAccountHidden = false;
    }

    this.declaration.phases = this.declaration.phases.map(p => {
      return {...p, show: false};
    });
    this.declaration.phases.forEach(phase => {
      phase.tasks.forEach(task => {

        if(task.value === null || task.value === "false") {
          task.value = false;
        }
        if(task.value === "true") {
          task.value = true;
        }
      });
    });

    if(!this.declaration.responsible) {
      this.declaration.responsible = {id: undefined};
    }

    const observations  = await this.getObservations(id, this.currentPage, this.itemsForPage);
    this.maxPages = observations.totalPages; 
    this.observations = observations.observations;

    const user = JSON.parse(localStorage.getItem('user'));
    this.responsibles = (await this.filtersService.getUsers()).filter(u => user.user.department !== 'GENERAL' ? u.department === user.user.department : u.department);
    this.declaration.phases.forEach(p => {
      if (this.declaration.status === StatusDeclarationEnum.Finished ) {
        p.show = false;
      } else {
        p.show = true;
      }
    });
    await this.disableTasks(this.declaration.presentationType);

  }

  async setFormat(event) {
    const format = event.value;
    this.isFormatChanged =  format !== this.declaration.client.format;
    await this.declarationService.editDeclaration(this.declaration.id, {type: TypeChangeEnum.Format, change: format});
  }

  async setPresentation(event) {
    const presentation = event.value;
    this.isPresentationChanged = presentation !== this.declaration.client.presentationType;
    await this.declarationService.editDeclaration(this.declaration.id, {type: TypeChangeEnum.Presentation, change: presentation});
    await this.disableTasks(presentation);
    if(this.disableAccountPresentations.includes(presentation)) {
      this.isAccountHidden = true;
    } else {
      this.isAccountHidden = false;
    }
  }

  async disableTasks(presentation) {
    for (let phase of this.declaration.phases) {
      for (let task of phase.tasks) {
        if (task.isDisabledByTypePresentation) {
          if (task.isDisabledByTypePresentation.includes(presentation)) {
            task.isDisabled = true;
          }
          else {
            task.isDisabled = false;
          }
        }
      }
    }
  }

  async setAccount(event) {
    const account = event.target.value;
    this.isAccountChanged =  account !== this.declaration.client.bankAccount;
    await this.declarationService.editDeclaration(this.declaration.id, {type: TypeChangeEnum.Account, change: account});
  }

  async setResponsible(event) {
    const id = event.value;
    await this.declarationService.editDeclaration(this.declaration.id, {type: TypeChangeEnum.Responsible, change: id});
  }


  async getObservations(id, currentPage, itemsForPage) {
    return await this.declarationService.getObservationsByDeclaration(id, currentPage, itemsForPage);
  }

  showAllFases() {
    this.allExpanded = !this.allExpanded;
    if(this.allExpanded) {
      this.declaration.phases.forEach(p => {
        p.show = true;
      });
    } else {
      this.declaration.phases.forEach(p => {
        p.show = false;
      });
    }
  }

  async commentAdd(comment?) {
    let observation = {
      declaration: this.declaration.id,
      comment: comment ? comment : this.commentForm.value.comment
    }
    this.declarationService.createObservation(observation);
    await this.getObservations(this.declaration.id, this.currentPage, this.itemsForPage);
    this.commentForm.reset();
    await this.getData(this.declaration.id);
  }

  getInitials(name: string): string {
    return name.split(' ').map(word => word[0].toUpperCase()).join('');
  }

  async setToNoProceed(comment) {
    await this.commentAdd(comment);
    this.declarationService.editDeclaration(this.declaration.id, {type: TypeChangeEnum.Status, change: StatusDeclarationEnum.NotApplicable});
    this.router.navigate(['/declarations']);
  }

  openCommentModal() { 
    this.showCommentModal = true;
  }

  closeCommentModal() {
    this.showCommentModal = false;
  }

  openReactivateModal() { 
    this.showReactivateModal = true;
  }

  closeReactivateModal() {
    this.showReactivateModal = false;
  }

  allPhasesSelected() {
    return this.declaration.phases.every(phase => phase.tasks.every(task => task.value === true));
  }


  setToFinished() {
    this.declarationService.editDeclaration(this.declaration.id, {type: TypeChangeEnum.Status, change: StatusDeclarationEnum.Finished});
    this.router.navigate(['/declarations']);
  }

  onScroll() {
    if(this.currentPage < this.maxPages) {
      this.currentPage++;
      this.getObservations(this.declaration.id, this.currentPage, this.itemsForPage).then(observations => {
        this.observations = this.observations.concat(observations.observations);
      });
    }
  }

  isOnPeriod() {
    return this.today <= new Date(this.declaration.dateLimit);
  }

  async setToProgress(comment) {
    await this.commentAdd(comment);
    await this.declarationService.editDeclaration(this.declaration.id, {type: TypeChangeEnum.Status, change: StatusDeclarationEnum.Progress});
    this.isBlocked = false;
    this.router.navigate(['/declarations']);
  }

  disableBankAccountAndPresentation(event) {
    console.log(event);
    if(event === true) {
      this.isAccountDisabled = true;
      this.isPresentationDisabled = true;
    } else {
      if(!this.disableAccountPresentations.includes(this.declaration.presentationType)) {
      this.isAccountDisabled = false;
      }
      this.isPresentationDisabled = false;
    }
  }

}
