<div class="container-fluid title">
    <div class="columns is-multiline">
        <div class="column is-10">
            <h1 class="title">Gestor Clientes</h1>
        </div>
        <div class="column is-2">
            <button class="button create-client-button" (click)="createClient()">
                <fa-icon class="create-client-icon" [icon]="faUserPlus"></fa-icon>
                Nuevo Cliente
            </button>
        </div>

    </div>
</div>
<div class="container-fluid content-page background">
    <div class="columns is-multiline filters-container">
        <div class="column is-2">
            <p-dropdown
            [options]="clients" 
            [(ngModel)]="selectedClients" 
            optionLabel="name"
            optionValue="name"
            [filter]="true" 
            filterBy="name" 
            [showClear]="true"
            placeholder="Cliente"
            (onClear)="getData()"
            (onChange)="onClientSelected($event)"/>

        </div>
        <div class="column is-2">
            <p-dropdown
            [options]="clientCodes"
            [(ngModel)]="selectedClientCodes" 
            optionLabel="code"
            optionValue="code"
            [filter]="true" 
            filterBy="code" 
            [showClear]="true"
            placeholder="Cod. Cliente"
            (onClear)="getData()"
            (onChange)="onClientCodeSelected($event)"/>

        </div>
        <div class="column is-2">
            <p-dropdown
                [options]="personTypes"
                [filter]="false"
                [(ngModel)]="selectedPersonType"
                optionLabel="label"
                optionValue="value"
                placeholder="Tipo de cliente"
                (onChange)="onPersonTypeSelected($event)"
                (onClear)="getData()"
                [showClear]="true"
            />

        </div>
        <div class="column is-2">
            <p-multiSelect
                [options]="responsables"
                [filter]="false"
                [(ngModel)]="selectedResponsible"
                optionLabel="name"
                optionValue="id"
                placeholder="Responsable"
                (onChange)="onResponsibleSelected($event)"
                (onClear)="getData()"
                [showClear]="true"
            />

        </div>
        
    </div> 
    @if (!isLoading) {
        <app-clients-table [configTable]="configTable"></app-clients-table>
    <div class="pagination-container">
        <app-pagination
            [currentPage]="currentPage" 
            [totalPages]= "maxPages"
            (pageChange)="currentPage = $event; getData()">
        </app-pagination>
    </div>
    }@else {
        <div class="column is-12" style="display: flex; justify-content: center; margin-top: 100px;">
            <p-progressSpinner ariaLabel="loading"
            styleClass="custom-spinner"/>        </div>
    }
    
</div>