import { Component } from '@angular/core';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { ClientsTableComponent } from '../../../components/clients-table/clients-table.component';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CommonModule } from '@angular/common';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { CLIENT_TYPES } from '../../../core/constants/client.constants';
import { FiltersService } from '../../../core/services/declarations/filters.service';

@Component({
  selector: 'app-clients-list',
  standalone: true,
  imports: [
    ClientsTableComponent, 
    FontAwesomeModule, 
    PaginationComponent, 
    ProgressSpinnerModule, 
    CommonModule, 
    MultiSelectModule, 
    DropdownModule,
    FormsModule
  ],
  templateUrl: './clients-list.component.html',
  styleUrl: './clients-list.component.scss'
})
export class ClientsListComponent {

  faUserPlus = faUserPlus;

  headers = [ 
    {
      name: 'Código Cliente',
      key: 'code'
    },
    {
      name: 'Grupo Cliente',
      key: 'codeApplication'
    },
    {
      name: 'Cliente',
      key: 'name'
    },
    // {
    //   name: 'Area',
    //   key: 'area'
    // },
    {
      name: 'Responsable interno',
      key: 'responsible'
    },
    // {
    //   name: 'Persona de contacto',
    //   key: 'contact'
    // },
    // {
    //   name: 'Facturación',
    //   key: 'billing'
    // },
    {
      name: 'Estado',
      key: 'state'
    }];

    configTable = {
      headers: this.headers,
      data: undefined
    }

    currentPage = 0;
    itemsForPage = 10;
    maxPages = undefined;
    isLoading: boolean;

    selectedClients = undefined;
    selectedPersonType = undefined;
    selectedClientCodes = undefined;
    selectedResponsible = undefined;

    personTypes = CLIENT_TYPES;
    responsables = [];
    clients = [];
    clientCodes = [];
  
    constructor (
      private clientsService: ClientsService,
      private filtersService: FiltersService,
      private router: Router
    ) { }

    async ngOnInit() { 
      this.isLoading = true;
      this.clients = await this.filtersService.getClients();
      this.clientCodes = this.clients.filter(client => client.code !== null);
      this.responsables = await this.filtersService.getUsers();
      await this.loadPage();
    }
    
    async loadPage() {
      await this.getData();
    }

    async getData() {

      let data =  await this.clientsService.getClients(this.currentPage, this.itemsForPage, this.selectedClients, this.selectedClientCodes, this.selectedPersonType, this.selectedResponsible);
      this.maxPages = data.totalPages+1;
      data.clients.forEach(client => {
        client.state = client.endDate ? 'INACTIVE' : 'ACTIVE';
        client.responsible = client.responsible ? client.responsible.name : 'No asignado';
      });
      this.configTable = {...this.configTable, headers: this.headers, data: data.clients};
      this.isLoading = false;
    }

    createClient() {
      this.router.navigate(['/clients/detail/new']);
    }

    async onClientSelected(event) {
      console.log(event.value)
      this.selectedClients = event.value;
      this.getData();
    }

    async onClientCodeSelected(event) {
      console.log(event.value)
      this.selectedClientCodes = event.value;
      this.getData();
    }

    async onPersonTypeSelected(event) {
      console.log(event.value)
      this.selectedPersonType = event.value;
      this.getData();
    }

    async onResponsibleSelected(event) {
      console.log(event.value)
      this.selectedResponsible = event.value;
      this.getData();
    }

}
